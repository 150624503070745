import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom'; 
 
import axios from 'axios'; 
import {api_base_url, base_url} from '../common/Constants';
 
class CompaniesContent extends React.Component{

    constructor(){
        super();

        
        this.state = {
            content : [],
            loading : 1
        }
        

    }

    componentDidMount()
    {
        if(JSON.parse(localStorage.getItem("content")))
            {
               this.setState({content:JSON.parse(localStorage.getItem("content"))});
               this.setState({loading:0})
            }
            else
            {
                axios.get(api_base_url+"content").then(response=>{
                    this.setState({content:response.data}); 
                    this.setState({loading:0})
                });
            }
    }


    render()
    { 
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
    return (
        <div>


        <section className="about-three section-space" id="about">
            <div className="container">
                <div className="row gutter-y-60">
                    <div className="col-lg-6 wow fadeInLeft" data-wow-duration="1500ms">
					
                        <div className="about-three__image">
                            <img src={this.state.content[2].image} alt="about image" className="about-three__image__one" />
                            
                            
                        </div> 
                    </div> 
                    <div className="col-lg-6 wow fadeInRight" data-wow-duration="1500ms">
                        <div className="about-three__content">
                            <div className="sec-title @@extraClassName">

                                <img src="assets/images/shapes/sec-title-s-1.png" alt="about boskery meat shop" className="sec-title__img" />


                                <h6 className="sec-title__tagline">{this.state.content[2].subtitle}</h6> 

                                <h4 className="sec-title__title">{this.state.content[2].title}</h4> 
                            </div> 
                            <p className="about-three__text"><div dangerouslySetInnerHTML={{ __html: this.state.content[2].body }} /></p> 
                             
                        </div> 
                    </div> 
                </div> 
            </div> 
            
        </section> 
 
        <br />
        <br />
        </div>
    );
}

}

export default CompaniesContent;