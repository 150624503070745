import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom'; 
 
import axios from 'axios'; 
import {api_base_url, base_url} from '../common/Constants';

 
class SearchContent extends React.Component{

    constructor(props){
        super(props);

        
        this.state = {
            products : [],
            loading : 1 
        }
    }
 
    componentDidMount()
    {
        var data = { search_word : this.props.slug} 
            axios.post(api_base_url+"search_products", data).then(response=>{
                this.setState({products:response.data}); 
                this.setState({loading:0});
            }); 
    }
    componentDidUpdate(prevProps)
    {
        var data = { search_word : this.props.slug}
        if (this.props.slug !== prevProps.slug)
        {
            axios.post(api_base_url+"search_products", data).then(response=>{
                this.setState({products:response.data}); 
                this.setState({loading:0});
            });
        }
    }


    render()
    { 
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
    return (
        
        <div>
 
        <section className="product-page section-space-bottom">
            <div className="container">
                <div className="row justify-content-center gutter-y-30">
                    <div className="col-lg-12">
                        

                        <div className="row gutter-y-30">
                            
                        {this.state.products.map((prod,key)=>{
 
                            return <div className="col-xl-3 col-lg-4 col-sm-6">
                            <div className="product__item wow fadeInUp" data-wow-duration='1500ms' data-wow-delay='00ms'>
                                <div className="product__item__image">
                                <Link to={`/products/${prod.slug}`}  >
                                    <img src={prod.image[0]} alt={prod.title} />
                                </Link>
                                </div> 
                                <div className="product__item__content">
                                    
                                    <h4 className="product__item__title"><Link to={`/products/${prod.slug}`} >{prod.title}</Link></h4> 
                                    <div className="product__item__price price-strike"  id={prod.discount > 0 ? "view_old_price" : "hide_old_price"}>{prod.old_price}</div> 
                            
                                    <div className="product__item__price">{prod.price}</div> 
                                    <Link to={`/products/${prod.slug}`} className="boskery-btn product__item__link">
                                        <span className="boskery-btn__hover"></span>
                                        <span className="boskery-btn__hover"></span>
                                        <span className="boskery-btn__hover"></span>
                                        <span className="boskery-btn__hover"></span>
                                        <span className="boskery-btn__hover"></span>
                                        <span className="boskery-btn__hover"></span>
                                        <span className="boskery-btn__text">View More</span>
                                        <i className="icon-meat-3"></i>
                                    </Link>
                                </div> 
                            </div> 
                            </div>  
                            })}
                        </div> 
                    </div> 
                    
                </div> 
            </div> 
        </section> 

        </div>
    );
}

}

export default SearchContent;