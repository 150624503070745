import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom'; 
 
import axios from 'axios';
import {api_base_url, base_url} from '../common/Constants';
 
class Slider extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            banners : this.props.banners,
            loading : 0
        }

        
        

    }
    componentDidMount()
    { 
        
            const script = document.createElement("script");
            script.src   = base_url+"assets/js/loadSlider.js";  
            script.async = true;
            document.body.appendChild(script);
 
            script.onload = () => {
            if (window.loadSlider && this.state.loading == 0 && this.props.banners.length > 0) {
            window.loadSlider();
            this.setState({loading:1})
            }
            };
      
         
    }
    componentDidUpdate()
    { 
      
            const script = document.createElement("script");
            script.src   = base_url+"assets/js/loadSlider.js";  
            script.async = true;
            document.body.appendChild(script);
 
            script.onload = () => {
            if (window.loadSlider && this.state.loading == 0 && this.props.banners.length > 0) {
            window.loadSlider();
            this.setState({loading:1})
            }
            };
      //  this.setState({loading: 0});
         
    }
     
    render()
    { 
        
    return (
        <section className="hero-slider-one hero-main-slider" id="home">
        <div id="boskery-owl__carousel--with-counter" className="hero-slider-one__carousel boskery-owl__carousel--with-counter owl-carousel" data-owl-options='{
    "loop": true,
    "animateIn": "fadeIn",
    "animateOut": "slideOutDown",
    "items": 1,
    "autoplay": true,
    "autoplayTimeout": 7000,
    "smartSpeed": 1000,
    "nav": false,
    "navText": ["<span className=\"icon-left-arrow\"></span>","<span className=\"icon-right-arrow\"></span>"],
    "dots": true,
    "margin": 0
    }'>
        {this.props.banners.map((item,key)=>{
 
 return  <div className="item">
 <div className="hero-slider-one__item">
     <div className="hero-slider-one__bg" style={{backgroundImage: `url(${item.image})`}}></div>
     <div className="container">
         <div className="row">
             <div className="col-xxl-12 col-xl-10 col-lg-10">
                 <div className="hero-slider-one__content">
                    <h5 className="hero-slider-one__sub-title">{item.title == "" ? "ALHUDA BUTCHERY" : item.title}</h5> 
                     <h3 className="hero-slider-one__title">
                     <div dangerouslySetInnerHTML={{ __html: item.subtitle }} /> 
                         <span className="hero-slider-one__title__overlay-group">
                             
                         </span>
                     </h3> 
                     
                 </div>
             </div>
         </div>
     </div>
 </div>
</div> 
   
})} 
 
            
        </div>
    </section> 
    );
}

}

export default Slider;