import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom'; 
 
import axios from 'axios'; 
import {api_base_url, base_url} from '../common/Constants';

 
class AboutContent extends React.Component{

    constructor(){
        super();

        
        this.state = {
            content : [],
            page_content:[],
            loading : 1,
            settings:{}
        }
        

    }

    componentDidMount()
    {
        if(JSON.parse(localStorage.getItem("settings")))
            {
                this.setState({settings:JSON.parse(localStorage.getItem("settings"))});
                this.setState({content:JSON.parse(localStorage.getItem("content"))[0]});
                this.setState({page_content:JSON.parse(localStorage.getItem("page_content"))});
                this.setState({loading:0})
            }
            else
            {
                    axios.get(api_base_url+"settings").then(response=>{
                        this.setState({settings:response.data}) ;
                    });
                    axios.get(api_base_url+"content").then(response=>{
                        this.setState({content:response.data[0]}); 
                        axios.get(api_base_url+"page_content").then(response=>{
                            this.setState({page_content:response.data}); 
                            this.setState({loading:0})
                        }); 
                    });
                }
    }


    render()
    { 
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
    return (
        <div>
  <section className="about-three section-space" id="about">
            <div className="container">
            <div className="row gutter-y-60">
            <div className='col-lg-5'></div>
            <div className='col-lg-2'><img src={this.state.content.image}  alt={this.state.settings.site_title}  className="sec-title__img" width="200" />
            </div>
            <div className='col-lg-5'></div>
            </div>
                <div className="row gutter-y-60">
                
                    <div className="col-lg-12 wow fadeInRight" data-wow-duration="1500ms">
                        
                        <div className="about-three__content">
                              
                        <div dangerouslySetInnerHTML={{ __html: this.state.content.body }} />  
                            
                        </div> 
                    </div> 
                </div> 
            </div> 
            
        </section> 

        <section className="about-three section-space" id="about">
            <div className="container">
                <div className="row gutter-y-60">
                    <div className="col-lg-6 wow fadeInLeft" data-wow-duration="1500ms">
					
                        <div className="about-three__image">
                            <img src={this.state.page_content[0].image} alt={this.state.page_content[0].title} className="about-three__image__one" />
                            
                            
                        </div> 
                    </div> 
                    <div className="col-lg-6 wow fadeInRight" data-wow-duration="1500ms">
                        <div className="about-three__content">
                            <div className="sec-title @@extraClassName">
                            <img src="assets/images/shapes/sec-title-s-1.png" alt="DELIVERY SERVICE" className="sec-title__img" />

                                <h4 className="sec-title__title">{this.state.page_content[0].title}</h4> 
                            </div> 
                            <p className="about-three__text">{this.state.page_content[0].description} <br /><br />
							 </p> 
                            
                            
                            
                        </div> 
                    </div> 
                </div> 
            </div> 
            
        </section> 


        <section className="delivery-one section-space" id="delivery">
            <div className="container">
                <div className="row gutter-y-60">
                    <div className="col-lg-6 wow fadeInLeft" data-wow-duration="1500ms">
                        <div className="delivery-one__content">
                            <div className="sec-title @@extraClassName">

                                <img src="assets/images/shapes/sec-title-s-1.png" alt={this.state.page_content[1].title} className="sec-title__img" />
 
                                <h2 className="sec-title__title">{this.state.page_content[1].title}</h2> 
                            </div> 
                            <p className="delivery-one__text">{this.state.page_content[1].description}</p> 
                            
                        </div> 
                    </div> 
                    <div className="col-lg-6 wow fadeInRight" data-wow-duration="1500ms">
                        <div className="delivery-one__image">
                            <div className="about-three__image">
                                <img src={this.state.page_content[1].image} alt="delivery man" className="delivery-one__image__one" />
                                
                                
                            </div> 
                            
                        </div> 
                    </div> 
                </div> 
            </div> 
            
        </section> 
        <br />
        <br />
        </div>
    );
}

}

export default AboutContent;