 
import React from 'react';
import {  Link, useNavigate } from 'react-router-dom'; 
  
import { base_url} from '../../common/Constants';

 
 
class LeftMenu extends React.Component{
    
    constructor(props){
        super(props);

       

 
        this.state = { 
            user:{},
            show:false
        }
        

    }

    componentDidMount()
    {
        
    }

    handleSubmit = (event) => {
        event.preventDefault();
        localStorage.removeItem('user_id');
        
        this.props.navigate('/user/login');
    }
  

    render()
    { 
        if (this.state.loading === 1) {
            return <div>Loading...</div>;
          }
    return ( 
                    <div >
                        <ul className='profile-left'>
                            <li>
                            <Link to={base_url+"user/profile"} className={this.props.selected === "profile" ? "profile-link active" : "profile-link" }>
                                    <i className="icon-play" aria-hidden="true"></i>
                                    <span className="">Dashboard</span>
                                </Link> 
                            </li>
                            <li>
                            <Link to={base_url+"user/orders"}  className={this.props.selected === "orders" ? "profile-link active" : "profile-link" }>
                                    <i className="icon-play" aria-hidden="true"></i>
                                    <span className="">Orders</span>
                                </Link> 
                            </li>
                            <li>
                            <Link to={base_url+"user/edit"}  className={this.props.selected === "edit" ? "profile-link active" : "profile-link" }>
                                    <i className="icon-play" aria-hidden="true"></i>
                                    <span className="">Edit Profile</span>
                                </Link> 
                            </li>
                            <li>
                            <Link to={base_url+"user/change-password"}  className={this.props.selected === "change-password" ? "profile-link active" : "profile-link" }>
                                    <i className="icon-play" aria-hidden="true"></i>
                                    <span className="">Change Password</span>
                                </Link> 
                            </li>
                            <li>
                            <Link to={base_url+"user/logout"}  onClick={this.handleSubmit}  className={this.props.selected === "logout" ? "profile-link active" : "profile-link" }>
                                    <i className="icon-play" aria-hidden="true"></i>
                                    <span className="">Logout</span>
                                </Link> 
                            </li>
                        </ul>
                    </div>
                     
    )
    }

}

export function AppWithRouter(props){
    const navigate = useNavigate();
    return  (<LeftMenu navigate={navigate} selected={props.selected}></LeftMenu>)
}

export default LeftMenu;