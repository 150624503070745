import React, {Component} from 'react';

 
import axios from 'axios'; 
import { BrowserRouter, Routes, Link, Route } from 'react-router-dom'; 
import {api_base_url, base_url} from '../common/Constants'; 
import { SharedContext } from '../pages/createContext';

class MobileMenu extends Component {
    static contextType = SharedContext;
    constructor(){
        super();
        
        this.state = { 
            loading:1, 
            settings:{}

        }
    }

    componentWillMount()
    {
        
        if( localStorage.getItem("categories") &&  localStorage.getItem("categories") != null &&  localStorage.getItem("categories") !== undefined)
        { 
            var user = JSON.parse(localStorage.getItem("user"));
            this.setState({categories:JSON.parse(localStorage.getItem("categories"))});
            this.setState({page_content:JSON.parse(localStorage.getItem("page_content"))});
            this.setState({name:user.name}); 
            this.setState({loading:0}); 
            this.setState({cart:JSON.parse(localStorage.getItem("cart"))});   
            this.setState({loading:0});  
            this.setState({cartload:0});  
            this.setState({cartcount:JSON.parse(localStorage.getItem("cart")).length}); 
        }
        else{
        axios.get(api_base_url+"settings").then(response=>{
            this.setState({settings:response.data}) ;
        });
        const user_id =  localStorage.getItem('user_id');
        axios.get(api_base_url+"categories").then(response=>{
            this.setState({posts:response.data})
            localStorage.setItem("categories", JSON.stringify(response.data.categories));

                if(user_id !== "" && user_id !== null  &&  user_id !== undefined)
                { 
               
                   var data = { user_id : user_id}
                    axios.post(api_base_url+"getuser", data).then(response=>{
                        this.setState({name:response.data.name}); 
 
                       

                    });
                 }
                 const order_session_id =  localStorage.getItem('order_session_id');
                 var data = { order_session_id : order_session_id}
                 axios.post(api_base_url+"cart", data).then(response=>{
                     this.setState({cart:response.data});   
                     this.setState({loading:0});  
                     this.setState({cartload:0});  
                     this.setState({cartcount:response.data.cart.length});  
                 });
        });
    }
       
    }

    render()
    {
        
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
  return (
    
     <div></div>
    
  );
}
};

export default MobileMenu;