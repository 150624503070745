import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route, useNavigate } from 'react-router-dom'; 
import axios from 'axios';  
import { ElementsConsumer, CardElement , Elements} from "@stripe/react-stripe-js";
import {loadStripe} from '@stripe/stripe-js';

import PaymentSection from "./PaymentSection"; 
import {Stripe_Code, api_base_url, base_url} from '../common/Constants';

const stripePromise = loadStripe(Stripe_Code);


class MakePaymentContent extends React.Component{

    constructor(){
        super();

        this.state = {
            content : [],
            loading : 1, 
            error:'',
            stripe:stripePromise,
            options: '',
            message:''    
        }
    

    }
 
    componentDidMount()
    {
       
        const order_id =  localStorage.getItem('order_id');
        if(order_id === "" ||order_id === null || order_id === undefined)
            {
                window.location.href = '/';
            }
            else{
                var data = { order_id : order_id}
                axios.post(api_base_url+"get_order", data).then(response=>{
                    this.setState({order:response.data}); 
                   
                    axios.post(api_base_url+"get_stripe_secret", data).then(response=>{
                      this.setState({options:response.data}); 
                      this.setState({loading:0}); 
                    
                  });

                });
          }
  }
 
    
  componentWillMount()
  {
      const order_id =  localStorage.getItem('order_id');
      if(order_id === "" ||order_id === null || order_id === undefined)
          {
              window.location.href = '/';
          }
          else{
              var data = { order_id : order_id}
              axios.post(api_base_url+"get_stripe_secret", data).then(response=>{
                  this.setState({options:response.data}); 
               
              });
        }
}

  


    render()
    { 
      const appearance = {
        theme: 'stripe',
        variables: {
          colorPrimary: '#2c9be9',
          colorBackground: '#f7f7f7',
          colorText: '#333333',
          colorDanger: '#e74c3c',
          fontSize: '16px',
        },
      };
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
    return (
        <div>
        <section className="checkout-page section-space">
            <div className="container">
                <div className="row gutter-y-30">
                <div className="col-xl-3 col-lg-3"></div>
                    <div className="col-xl-6 col-lg-6 col-12">
                    <div className="stripe-form">
                    <div className="about-three__image">
                      <br/>
                           <p>Thank you for your order. Please complete the payment to confirm your order.!</p> 
                           <p><b>Amount to be paid:</b> {this.state.order.order_total}</p>
                        
                            
                        </div> 
                    
                    <Elements stripe={stripePromise} options={{
        clientSecret:this.state.options.clientSecret,
        appearance,  
      }}  >
                      <PaymentSection   />
                    </Elements>
                   
                   
                    <div className={this.state.error !="" ? 'alert alert-warning dblock' :'dnone'} >{this.state.message}</div>
                    <br/>
                   
                  </div>
                    </div> 
                </div>
            </div>
        </section> 

<br /><br />
            </div>
    );
}

}
 
export default MakePaymentContent;