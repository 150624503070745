import React, {Component} from 'react';

 
import axios from 'axios'; 
import { BrowserRouter, Routes, Link, Route } from 'react-router-dom'; 
import {api_base_url, base_url} from '../common/Constants'; 
import { SharedContext } from '../pages/createContext';

class Header extends Component {
    static contextType = SharedContext;
    constructor(){
        super();
        
        this.state = {
            posts : [],
            name:'Login',
            cart : [],
            loading:1,
            cartcount:0,
            settings:{},
            mobileMenu:"",
            searchPopup:"",
            searchWord:"",
            subMenu:false,
            categories:[]

        }
    }

    componentDidMount()
    { 
        
        const user_id =  localStorage.getItem('user_id');
        var data = { user_id : user_id}
        axios.post(api_base_url+"get-home-data", data).then(response=>{
            this.setState({home:response.data}) ;  
            this.setState({posts:response.data.categories});
            this.setState({settings:response.data.settings});
            this.setState({categories:response.data.categories});
            this.setState({cart:response.data.cart.cart});
            this.setState({name:(response.data.user === "" ? "Login" : response.data.user.name)});
            this.setState({loading:0}); 
            this.setState({cartload:0});  
            this.setState({cartcount:response.data.cart.cart.length});  

            localStorage.setItem('delivery_charges',parseFloat(response.data.settings.site_delivery_charges)) ;
            localStorage.setItem("settings", JSON.stringify(response.data.settings));
            localStorage.setItem("banners", JSON.stringify(response.data.banners));
            localStorage.setItem("categories", JSON.stringify(response.data.categories));
            localStorage.setItem("popularProducts", JSON.stringify(response.data.popular_products));
            localStorage.setItem("offerProducts", JSON.stringify(response.data.offer_products));
            localStorage.setItem("cart", JSON.stringify(response.data.cart));
            localStorage.setItem("user", JSON.stringify(response.data.user));
            localStorage.setItem("blogs", JSON.stringify(response.data.blogs));
            localStorage.setItem("page_content", JSON.stringify(response.data.page_content));
            localStorage.setItem("content", JSON.stringify(response.data.content));
           
            
        });
         
    }

    handleMobileMenu = (event) => {
        this.setState({mobileMenu:"expanded"});
     }

    handleMobileMenuClose= (event) => {
        this.setState({mobileMenu:""});
    }
     
    handleSearchBox = (event) => {
        this.setState({searchPopup:"active"});
     }

     handleSearchBoxClose= (event) => {
        this.setState({searchPopup:""});
    }
     
    handleSearch= (event) => {
       // this.setState({searchPopup:""});
    }
     
    handleSearchWord= (event) => {
        this.setState({searchWord:event.target.value});
    }
    menuClickHandler= (event) => {
        this.setState({mobileMenu: false});
       }
    subMenuHandler= (event) => {
        if(this.state.subMenu)
        {
            this.setState({subMenu: false});
        }
        else{
            this.setState({subMenu: true});
        }
        
    }
                
    render()
    {
        

  return (
    <div>
        <div className={this.state.mobileMenu == "" ? "mobile-nav__wrapper" : "mobile-nav__wrapper expanded"}> 
                <div className="mobile-nav__overlay mobile-nav__toggler"   onClick={this.handleMobileMenuClose}></div>
               
                <div className="mobile-nav__content">
                    <span className="mobile-nav__close mobile-nav__toggler"  onClick={this.handleMobileMenuClose}><i className="fa fa-times"></i></span>
        
                    <div className="logo-box">
                        <Link to="/" aria-label="logo image"><img src={base_url+"assets/images/logo-dark.png"}width="100" alt="logo" /></Link>
                    </div>
                   
                    <div className="mobile-nav__container">
                                            <ul className="main-menu__list">
        
         
                                                <li className="dropdown"> 
                                                  
                                                    <Link  to={base_url+`shop`}>Our Products<button aria-label="dropdown toggler" onClick={this.subMenuHandler}><i class="fa fa-angle-down"></i></button></Link>
                                                    <ul className="sub-menu" style={{ display: this.state.subMenu ? "block" : "none" }}>
                                                        <li><Link to={base_url+"shop"} onClick={this.menuClickHandler}>All Products</Link></li>
                                                        {this.state.posts.map((item,key)=>{
                                                            return <li><Link  to={base_url+`category/${item.slug}`}  onClick={this.menuClickHandler}>{item.name}</Link></li>
                                                        })}
                                                                                                 
                                                                                            </ul>
                                                                                        </li>
        <li>
                                                    <Link to={base_url+"gallery"} onClick={this.menuClickHandler}>Gallery</Link>
                                                </li>
                                                <li>
                                                    <Link to={base_url+"blog"} onClick={this.menuClickHandler}>Blog</Link>
                                                    
                                                </li>
                                                <li>
                                                    <Link to={base_url+"about-us"} onClick={this.menuClickHandler}>About Us</Link>
                                                </li>
                                                
                                                <li>
                                                    <Link to={base_url+"our-companies"} onClick={this.menuClickHandler}>Our Companies</Link>
                                                    
                                                </li>
                                                <li>
                                                    <Link to={base_url+"careers"} onClick={this.menuClickHandler}>Careers</Link>
                                                </li>
                                                
                                                <li>
                                                    <Link to={base_url+"contact-us"} onClick={this.menuClickHandler}>Contact Us</Link>
                                                </li>
                                                 
                                            </ul>
                                        </div>
                    
                    <ul className="mobile-nav__contact list-unstyled">
                    <li>
                                            <i className="icon-maps-and-flags"></i>
                                            <Link to="https://maps.app.goo.gl/WK5WbfScfDV9PvfQ8">{this.state.settings.contact_address1}</Link>
                                        </li>
										<li>
                                            <i className="icon-maps-and-flags"></i>
                                            <Link to="https://maps.app.goo.gl/NsD1rwg8kPoZaV8t6">{this.state.settings.contact_address2}</Link>
                                        </li>
                    </ul> 
                    <div className="mobile-nav__social">
                    <Link to={this.state.settings.site_facebook_url} target="_blank">
                                                <i className="fab fa-facebook-f" aria-hidden="true"></i>
                                                <span className="sr-only">Facebook</span>
                                            </Link>
                                            
                                            
                                            <Link to={this.state.settings.site_instagram_url}  target="_blank">
                                                <i className="fab fa-instagram" aria-hidden="true"></i>
                                                <span className="sr-only">Instagram</span>
                                            </Link>
											<Link to={this.state.settings.site_youtube_url}  target="_blank">
                    						<i className="fab fa-youtube" aria-hidden="true"></i>
                    						<span className="sr-only">Youtube</span>
              								  </Link>
                    </div> 
                </div>
                
            </div>

            <div  className={this.state.searchPopup == "" ? "search-popup" : "search-popup active"}>
        <div class="search-popup__overlay search-toggler"  onClick={this.handleSearchBoxClose}></div>
         
        <div class="search-popup__content">
            <form role="search" method="post" class="search-popup__form" action="#">
                <input type="text" id="search" onChange={this.handleSearchWord} value={this.searchWord} placeholder="Search Here..." />
               <Link to={base_url+`search/${this.state.searchWord}`} ><button type="button" aria-label="search submit" class="boskery-btn"  >
                    <span class="boskery-btn__hover"></span>
                    <span class="boskery-btn__hover"></span>
                    <span class="boskery-btn__hover"></span>
                    <span class="boskery-btn__hover"></span>
                    <span class="boskery-btn__hover"></span>
                    <span class="boskery-btn__hover"></span>
                    <span class="icon-search"></span>
                </button></Link> 
            </form>
        </div>
        
    </div>
    
    <header className="main-header main-header--one sticky-header sticky-header--normal">
        
        <div className="main-header__inner">
                    <div className="main-header__logo">
                        
                        <Link to="/">
                            <img src={base_url+"assets/images/logo-dark.png"} alt={this.state.settings.site_title} width="150" />
                        </Link>
                    </div>
                    <div className="main-header__right">
                        <div className="topbar">
                            <div className="container-fluid">
                                <div className="topbar__inner">
                                    <ul className="list-unstyled topbar__info">
                                        
                                        <li>
                                            <i className="icon-maps-and-flags"></i>
                                            <Link to="https://maps.app.goo.gl/WK5WbfScfDV9PvfQ8">{this.state.settings.contact_address1}</Link>
                                        </li>
										<li>
                                            <i className="icon-maps-and-flags"></i>
                                            <Link to="https://maps.app.goo.gl/NsD1rwg8kPoZaV8t6">{this.state.settings.contact_address2}</Link>
                                        </li>
                                    </ul> 
                                    <div className="topbar__right">
                                        
                                        <div className="topbar__social">
                                            <Link to={this.state.settings.site_facebook_url}  target="_blank">
                                                <i className="fab fa-facebook-f" aria-hidden="true"></i>
                                                <span className="sr-only">Facebook</span>
                                            </Link>
                                            
                                            
                                            <Link to={this.state.settings.site_instagram_url}  target="_blank">
                                                <i className="fab fa-instagram" aria-hidden="true"></i>
                                                <span className="sr-only">Instagram</span>
                                            </Link>
											<Link to={this.state.settings.site_youtube_url}  target="_blank">
                    						<i className="fab fa-youtube" aria-hidden="true"></i>
                    						<span className="sr-only">Youtube</span>
              								  </Link>
                                        </div> 
                                    </div> 
                                </div> 
                            </div> 
                        </div> 
                        <div className="main-header__right__inner">
                            <div className="main-header__right__left">
                                <nav className="main-header__nav main-menu">
                                    <ul className="main-menu__list">

                                        <li>
                                            <Link to="/">Home</Link>
                                            
                                        </li>


                                        
                                        
                                        <li className="dropdown">
                                            <Link to="#">Our Products</Link>
                                            <ul className="sub-menu">
                                                <li><Link to={base_url+"shop"}>All Products</Link></li>
                                                {this.state.posts.map((item,key)=>{
                                                    return <li><Link  to={base_url+`category/${item.slug}`} >{item.name}</Link></li>
                                                })}
                                                 
                                            </ul>
                                        </li>
										
										<li>
                                            <Link to={base_url+"gallery"}>Gallery</Link>
                                        </li>
                                        <li>
                                            <Link to={base_url+"blog"}>Blog</Link>
                                            
                                        </li>
                                        <li>
                                            <Link to={base_url+"about-us"}>About Us</Link>
                                        </li>
                                        
										<li>
                                            <Link to={base_url+"our-companies"}>Our Companies</Link>
                                            
                                        </li>
                                        <li>
                                            <Link to={base_url+"careers"}>Careers</Link>
                                        </li>
                                        
                                        <li>
                                            <Link to={base_url+"contact-us"}>Contact Us</Link>
                                        </li>
                                    </ul>
                                </nav> 
                            </div> 
                            <div className="main-header__right__right">
                                <div className="mobile-nav__btn mobile-nav__toggler" onClick={this.handleMobileMenu}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div> 
                                 
                                <Link to="#" className="search-toggler main-header__search" onClick={this.handleSearchBox}>
                                    <i className="icon-search" aria-hidden="true"></i>
                                    <span className="sr-only">Search</span>
                                </Link> 
                                <Link to="/cart" className="main-header__cart">
                                    <i className="icon-cart" aria-hidden="true"></i>
                                    <span className="sr-only">Cart</span>
                                    <span className={this.props.cartpage  ? 'cart-count dblock' : 'cart-count dnone' }  >{this.context.sharedState}</span>
                                    <span className={!this.props.cartpage ? 'cart-count dblock' : 'cart-count dnone' }  >{this.state.cartcount}</span>
                                    </Link> 

                                <Link  to={base_url+"user/login"} className="boskery-btn main-header__btn "  id={this.state.name == "Login" ? "dblock" : "dnone"} >
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__text">Login</span>
                                    <i className="icon-meat-3"></i>
                                </Link>
                                
                                <Link  to={base_url+"user/profile"} className="boskery-btn main-header__btn "  id={this.state.name == "Login" ? "dnone" : "dblock"} >
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__hover"></span>
                                    <span className="boskery-btn__text">{this.state.name}</span>
                                    <i className="icon-meat-3"></i>
                                </Link> 
                            </div> 
                        </div> 
                    </div> 
        </div>
    </header>
    </div>
    
  );
}
};

export default Header;