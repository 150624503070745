import React, {Component} from 'react';
import axios from 'axios'; 
import {   Link } from 'react-router-dom'; 
import {api_base_url, base_url} from '../common/Constants';

class Footer extends Component {

    constructor(){
        super();
        this.state = {
            posts : [],
            name:'Login',
            cart : [],
            loading:1,
            settings:{}
        }
    }
    componentDidMount()
    { 

            if(localStorage.getItem("categories") &&  localStorage.getItem("categories") != null &&  localStorage.getItem("categories") !== "undefined"
            &&  localStorage.getItem("categories") !== undefined)
            {
                console.log(JSON.parse(localStorage.getItem("categories")));
               this.setState({posts:JSON.parse(localStorage.getItem("categories"))});
               this.setState({settings:JSON.parse(localStorage.getItem("settings"))}); 
               this.setState({loading:0}); 
            }
            else{
               axios.get(api_base_url+"categories").then(response=>{
                   this.setState({posts:response.data}); 
                   axios.get(api_base_url+"settings").then(response=>{
                       this.setState({settings:response.data}); 
                       this.setState({loading:0}); 
                   }); 
   
               });
            } 
        
       
    }

     
        scrollToTop = () => {
          window.scrollTo({
            top: 0,
            behavior: "smooth", // Smooth scrolling effect
          });
        };
      

    render()
    {
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
 
  return (

        <div>
           
            
            <Link to="/" data-target="html" onClick={this.scrollToTop} className="scroll-to-target scroll-to-top">
                <span className="scroll-to-top__text">back top</span>
                <span className="scroll-to-top__wrapper"><span className="scroll-to-top__inner"></span></span>
            </Link>
            

            <footer className="main-footer">
            <div className="main-footer__bg" style={{backgroundImage: `url(`+base_url+`assets/images/backgrounds/footer-bg.png)` }}></div>
            
            <div className="container">
                
                <div className="main-footer__widget">
                    <div className="row gutter-y-50">
                        <div className="col-lg-5 col-xl-3 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="00ms">
                            <div className="footer-widget footer-widget--about">
                                <Link to="/" className="main-footer__logo">
                                <img src={base_url+"assets/images/logo-dark.png"} width="119" alt={this.state.settings.site_title} />
                            </Link> 
								<h2 className="footer-widget__title">{this.state.settings.site_footer_text}</h2> 
                                
                            </div> 
                        </div> 
                        <div className="col-lg-4 col-md-3 col-xl-3 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                            <div className="footer-widget footer-widget--links">
                                <h2 className="footer-widget__title">Explore</h2> 
                                <ul className="list-unstyled footer-widget__links">
                                    <li><Link to={base_url+"shop"}>Products</Link> </li>
                                    <li><Link to={base_url+"gallery"}>Gallery</Link></li>
                                    <li><Link to={base_url+"blog"}>Blog</Link></li>
                                    <li><Link to={base_url+"about-us"}>About Us</Link></li>
                                    <li><Link to={base_url+"contact-us"}>Contact Us</Link></li>
                                </ul> 
                                <br/>
                                 <img src={this.state.settings.site_iso_logo} width="200" alt="ISO Logo" />
                            </div> 
                        </div> 
						<div className="col-lg-3 col-md-3 col-xl-2 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                            <div className="footer-widget footer-widget--links">
                                <h2 className="footer-widget__title">Our Products</h2> 
                                <ul className="list-unstyled footer-widget__links">
                                {this.state.posts.map((item,key)=>{
                                                    return <li><Link  to={base_url+`category/${item.slug}`} >{item.name}</Link></li>
                                                })}
                                </ul> 
                            </div> 
                        </div> 
                        <div className="col-lg-4 col-md-5 col-xl-4 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                            <div className="footer-widget footer-widget--contact">
                                <h2 className="footer-widget__title">Contact</h2> 
                                <div className="footer-widget__contact">
                                    <address className="footer-widget__address">
                                    {this.state.settings.contact_address1}</address> 
                                    <ul className="list-unstyled footer-widget__info">
                                        <li><span className="icon-paper-plane"></span> <Link to={"mailto: "+this.state.settings.contact_email}> {this.state.settings.contact_email}</Link></li>
                                        <li><span className="icon-phone-call"></span> <Link to={"tel:"+this.state.settings.contact_land_number1}>{this.state.settings.contact_land_number1}</Link>, <Link to={"tel:"+this.state.settings.contact_mobile_number1}>{this.state.settings.contact_mobile_number1}</Link></li>
                                    </ul> 
                                     <address className="footer-widget__address">{this.state.settings.contact_address2}</address> 
                                   <ul className="list-unstyled footer-widget__info">
                                        <li><span className="icon-paper-plane"></span> <Link to={"mailto: "+this.state.settings.contact_email}> {this.state.settings.contact_email}</Link></li>
                                        <li><span className="icon-phone-call"></span> <Link to={"tel:"+this.state.settings.contact_land_number2}>{this.state.settings.contact_land_number2}</Link>,  <Link to={"tel:"+this.state.settings.contact_mobile_number2}>{this.state.settings.contact_mobile_number2}</Link></li>
                                    </ul> 
                                    <div className="footer-widget__social">
                                        <Link to={this.state.settings.site_facebook_url}  target="_blank">
                                            <i className="fab fa-facebook-f" aria-hidden="true"></i>
                                            <span className="sr-only">Facebook</span>
                                        </Link>
                                        <Link to={this.state.settings.site_instagram_url}  target="_blank">
                                            <i className="fab fa-instagram" aria-hidden="true"></i>
                                            <span className="sr-only">Twitter</span>
                                        </Link>
                                        
                                        <Link to={this.state.settings.site_youtube_url} aria-hidden="true" target="_blank">
                                            <i className="fab fa-youtube"></i>
                                            <span className="sr-only">Youtube</span>
                                        </Link>
                                    </div> 
                                </div> 
                            </div> 
                        </div> 
                        
                    </div> 
                </div> 
            </div>
			
            <div className="main-footer__bottom">
                <div className="container">
                    <div className="main-footer__bottom__inner">
                        <p className="main-footer__copyright">
                            &copy; Copyright <span className="dynamic-year"></span> by Al Huda Butchery.
                        </p>
                        <p className="main-footer__design">
                            Design & developed by <Link to="https://integrateitsolutions.com/" target='_blank'>Integrate Solutions</Link>
                        </p>
                    </div>
                </div>
            </div>  
        </footer> 


        </div>
         
  
);
}
};

export default Footer;